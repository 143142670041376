<template>
  <div>
    <div v-show='!data1||data1.length==0'
         class="text">暂无数据</div>
    <div v-show='data1&&data1.length>0'>
      <div :id="myId"
           :style="{width: '100%', height: '500rem'}"></div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      myChart: undefined,
      tipText: ""
    }
  },
  props: ['myId', 'data1', 'xValues', 'color',],

  watch: {
    // 数据变化时重新绘图
    data1 () {
      this.clear()
      if (this.data1 && this.data1.length > 0) {
        this.$nextTick(() => this.drawLine())
      }
    },

  },
  methods: {
    clear () {
      //this.myChart = this.$echarts.init(document.getElementById(this.myId))
      if (this.myChart) {
        this.myChart.dispose()
      }
    },
    drawLine () {
      this.myChart = this.$echarts.init(document.getElementById(this.myId))
      // this.myChart.resize()

      this.myChart.setOption({
        tooltip: {
          trigger: 'axis',
          show: true,
        },
        // notMerge: true,
        legend: {
          show: false
          // icon: "circle",
          // bottom: '0',
          // left: '6%',
          // itemWidth: 13,
          // itemHeighht: 13,
          // padding: 10,
          // textStyle: {
          //   fontSize: 20,
          //   color: '#000'
          // }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true
        },
        color: this.color,

        toolbox: {
          show: false,
          feature: {
            saveAsImage: {},
          }
        },
        dataZoom: [
          {
            // start: 0,
            // end: 100,
            type: 'slider',
            startValue: 0,
            endValue: 5,
            xAxisIndex: [0, 1]
          },
          // {
          //   type: 'inside',
          //   startValue: 0,
          //   endValue: 5,
          //   xAxisIndex: [0, 1]
          // }
        ],
        xAxis: {
          type: 'category',
          boundaryGap: false,
          // data:
          data: this.xValues
        },
        yAxis: {
          type: 'value',
          max: 100

        },
        series: this.data1
      })
      window.addEventListener('resize', this.resizeChart)
    },
    //大小自适应
    resizeChart () {
      this.myChart.resize()
    }

  },
  destroyed () {
    window.removeEventListener('resize', this.resizeChart)
  }
}
</script>

<style lang='scss' scoped>
.text {
  text-align: center;
  line-height: 260rem;
  color: #999;
  font-size: 24rem;
}
</style>