<template>
  <div class="cishu_warp">
    <div class="title">
      {{"考点"}}得分率曲线<span>
        （每个{{"考点"}}的得分率变化情况）
      </span>
    </div>
    <charts myId='mychartScore'
            ref='chartsRef'
            :color="color"
            :data1="data1"
            :xValues="xValues"></charts>
    <!-- <div id="myCishu"
         :style="{width: '100%', height: '500rem'}"></div> -->
    <div class="colors">
      <div class="item"
           v-for="(item,index) in textList"
           :key="item">
        <span class="yuan"
              :style="{'background':color[index]}"></span>
        {{item}}
      </div>
    </div>
  </div>
</template>

<script>
import charts from './charts.vue'

export default {
  components: {
    charts
  },
  data () {
    return {
      data1: [],
      color: ['#FF6147', '#F2BE39', '#2196F3', '#3CDB91', '#6348F7', '#881280'],
      textList: [],
      xValues: []
    }
  },
  mounted () {
    // this.drawLine()
  },
  props: ['grade'],
  watch: {
    data1: {
      handler () {
        this.textList = []
        this.data1.forEach(item => {
          if (item.name) { this.textList.push(item.name) }
        })
      },
      deep: true
    }
  },
  methods: {

    // clear () {
    //   let myChart = this.$echarts.init(document.getElementById('myCishu'))
    //   myChart.clear()
    // },
    // drawLine () {
    //   this.clear()
    //   let myChart = this.$echarts.init(document.getElementById('myCishu'))
    //   myChart.setOption({
    //     tooltip: {
    //       trigger: 'axis',
    //       show: true,
    //     },
    //     legend: {
    //       data: this.textList,
    //       show: false,
    //     },

    //     color: this.color,
    //     grid: {
    //       left: '3%',
    //       right: '4%',
    //       bottom: '3%',
    //       containLabel: true
    //     },
    //     toolbox: {
    //       show: false,
    //       feature: {
    //         saveAsImage: {},
    //       }
    //     },
    //     xAxis: {
    //       type: 'category',
    //       boundaryGap: false,
    //       data: [' ', ' ', ' ', ' ', ' ', ' ']
    //     },
    //     yAxis: {
    //       type: 'value',
    //       max: 100
    //     },
    //     series: this.data1
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
.colors {
  display: flex;
  flex-wrap: wrap;
  margin-left: 7%;
  font-size: 20rem;
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10rem;
    .yuan {
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      margin-right: 10rem;
    }
  }
}

.title {
  font-size: 24rem;
  font-weight: bold;
  color: #000000;
  line-height: 25rem;
  padding-top: 20rem;
  text-indent: 20rem;
  span {
    font-size: 20rem;
    font-weight: bold;
    color: #999999;
  }
}
</style>